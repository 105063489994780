import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/icon.css'; //element-ui 图标库
import iconPicker from 'e-icon-picker';

Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.use(iconPicker, { ElementUI: true, });
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
router.beforeEach((to, from, next) => {
  next()
})